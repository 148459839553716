import React from 'react';

import countryISO3ToISO2Mapping from 'utils/country-iso-3-to-2';
import ImageRoot from 'components/common/atoms/Image';
import svgCurrencies from 'assets/images/svgCurrencies';
import svgCountries from 'assets/images/svgCountries';

import Styled from './CountryOrCurrencyFlag.styled';
import { CountryOrCurrencyFlagProps } from './CountryOrCurrencyFlag.types';


function CountryOrCurrencyFlag({
    currencyCode = '',
    countryCode = '',
    size = 2,
    useCountryISOAlpha3Code = false,
}:CountryOrCurrencyFlagProps) {
    const handlers = [
        {
            predicate: () => currencyCode !== '',
            handler: () => ({
                flagSrc: svgCurrencies[currencyCode?.toLowerCase()],
                imgAlt: currencyCode,
            }),
        },
        {
            predicate: () => countryCode !== '' && useCountryISOAlpha3Code,
            handler: () => ({
                flagSrc: svgCountries[countryISO3ToISO2Mapping[countryCode].toLowerCase()],
                imgAlt: countryCode,
            }),
        },
        {
            predicate: () => countryCode !== '' && !useCountryISOAlpha3Code,
            handler: () => ({
                flagSrc: svgCountries[countryCode?.toLowerCase()],
                imgAlt: countryCode,
            }),
        },
        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];

    const flagData = handlers.filter(({ predicate }) => predicate())[0].handler();

    return flagData?.flagSrc ? (
        <ImageRoot
            srcDark={flagData?.flagSrc}
            src={flagData?.flagSrc}
            width={`${size}rem`}
            alt={flagData?.imgAlt}
        />
    ) : (
        <Styled.MissingFlagPlaceholder size={`${size}rem`} />
    );
}


export default CountryOrCurrencyFlag;
