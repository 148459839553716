import React, { JSX, useEffect, useRef } from 'react';
import { ConsentScope, Uuid } from '@manigo/manigo-domain-typings';
import { FormProvider, useForm } from 'react-hook-form';
import { Heading } from '@chakra-ui/react';

import noop from 'utils/function-tools';
import ScrollableContainer from 'components/common/atoms/ScrollableContainer';
import AccountFormItem from 'components/common/organisms/AccountItem';

import ConsentDetailsDisplay from './ConsentDetailsDisplay';
import Styled from './NewConsentForm.styled';
import { NewConsentFormProps } from './NewConsentForm.types';
import NewConsentHeading from './NewConsentHeading';
import NewConsentButtons from './NewConsentButtons';


function NewConsentForm({
    entityDetailsData,
    accounts,
    t,
}: NewConsentFormProps): JSX.Element {
    const availableAccounts = (accounts || []).reduce((acc, account) => {
        return {
            ...acc,
            [`${account.extAccountId}`]: false,
        };

    }, {});

    type AccountsFormData = typeof availableAccounts;

    const previousAccountsSelection = useRef(availableAccounts);


    const formObject = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: { approvedAccounts: availableAccounts },
    });

    function findModifiedProperty(initial: AccountsFormData, current: AccountsFormData) {
        return Object.keys(initial).find(key => initial[key] !== current[key]) || null;
    }

    const {
        watch,
        handleSubmit,
        control,
        setValue,
    } = formObject;

    const approvedAccountsWatcher = watch('approvedAccounts');

    useEffect(() => {
        if (entityDetailsData?.entityDetails?.scope === ConsentScope.payments) {
            const a = findModifiedProperty(previousAccountsSelection.current, approvedAccountsWatcher);
            if (a) {
                const updatedValue = {
                    ...availableAccounts,
                    [a]: approvedAccountsWatcher[a],
                };

                previousAccountsSelection.current = updatedValue;
                setValue('approvedAccounts', updatedValue);
            }
        }
    }, [Object.values(approvedAccountsWatcher)]);

    const approvedAccounts: Uuid[] = Object.keys(approvedAccountsWatcher).reduce((acc: Uuid[], extAccountId: Uuid) => {
        return [
            ...acc,
            ...(approvedAccountsWatcher[extAccountId] === true ? [extAccountId] : []),
        ];
    }, []);

    return (
        <Styled.NewConsentLayout>
            <ScrollableContainer>
                <NewConsentHeading
                    consentRequestExpiry={entityDetailsData?.entityDetails?.consentRequestExpiry}
                    consentScope={entityDetailsData?.entityDetails?.scope}
                />

                <Styled.NewConsentFormContent>
                    <form
                        className="accountsList"
                        onSubmit={handleSubmit(() => noop)}
                        name="new-consent"
                        noValidate
                    >
                        <FormProvider {...formObject}>
                            <Styled.AccountsList>
                                {accounts && accounts?.length > 0
                                    ? (accounts || []).map((account) => (
                                        <AccountFormItem
                                            t={t}
                                            account={account}
                                            key={account.extAccountId}
                                            // @ts-expect-error TODO !!!
                                            control={control}
                                        />
                                    ))
                                    : (
                                        <Heading textAlign="center" size="sm">
                                            {t('consent:newConsentPage.noApplicableAccounts')}
                                        </Heading>
                                    )}
                            </Styled.AccountsList>
                        </FormProvider>
                    </form>

                    <ConsentDetailsDisplay
                        consentDetails={entityDetailsData?.entityDetails}
                        t={t}
                    />
                </Styled.NewConsentFormContent>
            </ScrollableContainer>

            <NewConsentButtons
                approvedAccounts={approvedAccounts}
                consentDetails={entityDetailsData?.entityDetails}
            />
        </Styled.NewConsentLayout>
    );
}


export default NewConsentForm;
