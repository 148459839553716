import React, { useMemo, JSX } from 'react';

import { ApplicationState } from 'models/app/applicationState';
import { AccessControlContent } from 'models/domain/accessControl';
import { EnhancedLocation } from 'models/app/navigation';
import { MAINTANCE_MODE_ENABLED } from 'config/environment';

import { ApplicationContentProps } from './ApplicationContent.types';
import Public from './ApplicationParts/Public.layout';
import AuthorisedVerified from './ApplicationParts/AuthorisedVerified.layout';
import AuthorisedNewConsent from './ApplicationParts/AuthorisedNewConsent.layout';
import AppMaintenancePlaceholder from '../AppMaintenancePlaceholder';
import AppLoadingPlaceholder from '../AppLoadingPlaceholder';


function renderProperApplicationPart({
    showSpinner,
    isAuthorised,
    accessControl,
    enhancedCurrentLocation,
    applicationState,
}: {
    showSpinner: boolean,
    isAuthorised: boolean,
    accessControl: AccessControlContent,
    enhancedCurrentLocation: EnhancedLocation,
    applicationState: ApplicationState
}): JSX.Element {

    const handlers: { predicate: boolean, handler: () => JSX.Element }[] = [
        {
            predicate: MAINTANCE_MODE_ENABLED,
            handler: () => <AppMaintenancePlaceholder />,
        },
        {
            predicate: showSpinner,
            handler: () => <AppLoadingPlaceholder />,
        },
        {
            predicate: !isAuthorised,
            handler: () => <Public enhancedCurrentLocation={enhancedCurrentLocation} accessControl={accessControl} />,
        },
        {
            predicate: isAuthorised && applicationState === ApplicationState.AUTHORISED_NEW_CONSENT,
            handler: () => <AuthorisedNewConsent accessControl={accessControl} enhancedCurrentLocation={enhancedCurrentLocation} />,
        },
        {
            predicate: isAuthorised,
            handler: () => <AuthorisedVerified accessControl={accessControl} enhancedCurrentLocation={enhancedCurrentLocation} />,
        },
        {
            predicate: true,
            handler: () => <div>A serious error!</div>,
        },
    ];

    return handlers.find(({ predicate }) => predicate)?.handler() as JSX.Element;
}

function ApplicationContent({
    accessControl,
    applicationState,
    enhancedCurrentLocation,
    isRefreshingSession,
    isI18nReady,
}: ApplicationContentProps): JSX.Element {
    const isAuthorised = useMemo(
        () => accessControl?.isAuthorised,
        [accessControl],
    );


    const showSpinner = useMemo(
        () => applicationState === ApplicationState.APPLICATION_STARTED
            || (accessControl.isAuthorised && ![ApplicationState.AUTHORISED_READY, ApplicationState.AUTHORISED_NEW_CONSENT].includes(applicationState))
            || !isI18nReady,

        [isAuthorised, applicationState, isRefreshingSession, isI18nReady],
    );

    return renderProperApplicationPart({
        showSpinner,
        accessControl,
        enhancedCurrentLocation,
        isAuthorised,
        applicationState,
    });

}


export default ApplicationContent;

