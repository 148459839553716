export function convertKeysToCamelCase(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map((item) => convertKeysToCamelCase(item));
    } else if (obj !== null && obj?.constructor === Object) {
        return Object.keys(obj).reduce((result, key) => {
            const camelCaseKey = key.replace(/_[a-zA-Z0-9]/g, (match) => match[1].toUpperCase());
            result[camelCaseKey] = convertKeysToCamelCase(obj[key]);
            return result;
        }, {});
    }
    return obj;
}
