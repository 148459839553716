import { DateFullIsoString } from '@manigo/manigo-domain-typings';

export interface RawJwtToken {
    accessToken: string; // encoded
    expirationTimeOfAccessToken: DateFullIsoString;
    expirationTimeOfRefreshToken: DateFullIsoString;
}

export enum AuthErrors {
    USER_LOCKED = 'Your account is locked due to multiple failed login attempts. For assistance, please contact your administrator.',

    // For active user, if user fails login 3 times backend throws this error
    TEMPORARILY_BLOCKED_FOR = 'User is temporarily locked for 3 minutes',

    // If user is not active ( KYC pending ), if user fails login 3 times backend throws this error
    TEMPORARILY_BLOCKED = 'User is temporarily locked',

    INVALID_CREDENTIALS = 'Invalid credentials.',
    CONFIRMATION_TOKEN_EXPIRED = 'jwt expired',
}
