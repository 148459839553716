import { object, TestContext } from 'yup';

export const defaultMaxLength = 255;

export const trimValue = (value = '') => value.trim();

export const noWhitespace = (value?: string) => !value || trimValue(value).length > 0;

/* eslint-disable spellcheck/spell-checker */
export const ipv4HttpsOnlyWebURLRegex = new RegExp(
    '^'
  // protocol identifier (mandatory)
  // short syntax // still required
  + '(?:(?:(?:https):)\\/\\/)'
  // user:pass BasicAuth (optional)
  + '(?:\\S+(?::\\S*)?@)?'
  + '(?:'
  // IP address exclusion
  // private & local networks
  + '(?!(?:10|127)(?:\\.\\d{1,3}){3})'
  + '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})'
  + '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})'
  // IP address dotted notation octets
  // excludes loopback network 0.0.0.0
  // excludes reserved space >= 224.0.0.0
  // excludes network & broadcast addresses
  // (first & last IP address of each class)
  + '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])'
  + '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}'
  + '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))'
  + '|'
  // host & domain names, may end with dot
  // can be replaced by a shortest alternative
  // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
  + '(?:'
  + '(?:'
  + '[a-z0-9\\u00a1-\\uffff]'
  + '[a-z0-9\\u00a1-\\uffff_-]{0,62}'
  + ')?'
  + '[a-z0-9\\u00a1-\\uffff]\\.'
  + ')+'
  // TLD identifier name, may end with dot
  + '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)'
  + ')'
  // port number (optional)
  + '(?::\\d{2,5})?'
  // resource path (optional)
  + '(?:[/?#]\\S*)?'
  + '$', 'i',
);

export const publicIpv4NoFtpWebURLRegex = new RegExp(
    '^'
  // protocol identifier (mandatory)
  // short syntax // still required
  + '(?:(?:(?:https?):)\\/\\/)'
  // user:pass BasicAuth (optional)
  + '(?:\\S+(?::\\S*)?@)?'
  + '(?:'
  // IP address exclusion
  // private & local networks
  + '(?!(?:10|127)(?:\\.\\d{1,3}){3})'
  + '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})'
  + '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})'
  // IP address dotted notation octets
  // excludes loopback network 0.0.0.0
  // excludes reserved space >= 224.0.0.0
  // excludes network & broadcast addresses
  // (first & last IP address of each class)
  + '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])'
  + '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}'
  + '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))'
  + '|'
  // host & domain names, may end with dot
  // can be replaced by a shortest alternative
  // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
  + '(?:'
  + '(?:'
  + '[a-z0-9\\u00a1-\\uffff]'
  + '[a-z0-9\\u00a1-\\uffff_-]{0,62}'
  + ')?'
  + '[a-z0-9\\u00a1-\\uffff]\\.'
  + ')+'
  // TLD identifier name, may end with dot
  + '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)'
  + ')'
  // port number (optional)
  + '(?::\\d{2,5})?'
  // resource path (optional)
  + '(?:[/?#]\\S*)?'
  + '$', 'i',
);
/* eslint-enabled spellcheck/spell-checker */

export const realFirstNamePattern = /^(?=([\p{Script=Latin}]+))\1(?=(([ -]?[\p{Script=Latin}]+)*))\2([\p{Script=Latin}]{0,2}[.]?)?$/u;
export const realLastNamePattern = /^(?=([\p{Script=Latin}]+['’]?[\p{Script=Latin}]+))\1(?=(([ -][\p{Script=Latin}]+['’]?[\p{Script=Latin}]+)*)?)\2$/u;

export const emailPatternRFC5322Compliant = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;

export const maxLengthRule = (value, t, maxLength = defaultMaxLength) => [{
    type: 'string',
    max: value || maxLength,
    message: t('common:validationMsg.maximumLength', { maxCount: value || maxLength }),
}];

export const isValidEmail = (t) => [{ type: 'email', message: t('common:validationMsg.emailInvalidFormat') }];

export const requiredRule = (t) => [{
    isRequired: true,
    message: t('common:validationMsg.fieldMandatory'),
}];


export const isValidV4UuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;


export const isValidV4Uuid = (uuid = '') => isValidV4UuidRegex.test(uuid);

export const createValidationSchemaFromFieldConfigs = (fieldsConfiguration) => {

    const objectSchema = fieldsConfiguration.reduce((acc, fieldConfiguration) => {
        if (fieldConfiguration?.optionalConfiguration?.validationRules) {
            return {
                ...acc,
                [fieldConfiguration?.name]: fieldConfiguration.optionalConfiguration.validationRules,
            };
        } else {
            return acc;
        }

    }, {});


    return object().shape({ ...objectSchema });
};

export const getValidationContext = (context:TestContext):any => context.options.context || {};
export default {
    trimValue,
    maxLengthRule,
    requiredRule,
    isValidEmail,
    publicIpv4NoFtpWebURLRegex,
    isValidV4Uuid,
    emailPatternRFC5322Compliant,
    createValidationSchemaFromFieldConfigs,
};
