import { TFunction } from 'i18next';
import { MdDarkMode, MdPowerSettingsNew } from 'react-icons/md';

import { ButtonConfiguration } from 'models/app/common';
import { EnhancedLocation } from 'models/app/navigation';
import { ShowModalPayload } from 'models/app/modal';
import { ShowConfirmationPayload } from 'store/alert/actions';


export const createUserDropdownItemsConfig = ({
    t,
    dispatchClearCurrentUser,
    // dispatchShowModal,
    toggleColorMode,
}: {
    t: TFunction,
    dispatchClearCurrentUser: () => void,
    dispatchShowModal: (payload: ShowModalPayload) => void,
    toggleColorMode: () => void,
}): {
    id: ButtonConfiguration['id'];
    icon?: ButtonConfiguration['icon'];
    label: ButtonConfiguration['label'];
    onClick?: ButtonConfiguration['onClick'];
    dataTestId: ButtonConfiguration['dataTestId'];
    pathname?: EnhancedLocation['pathname'];
    confirmation?: ShowConfirmationPayload;
    addDividerTop?: boolean;
}[] => ([
    {
        icon: MdDarkMode,
        label: t('common:appUi.userDropdown.toggleColorMode.text'),
        onClick: toggleColorMode,
        id: 'nav-menu-item-color-mode',
        dataTestId: 'nav-menu-item-color-mode',
        addDividerTop: true,
    },
    // TODO restore after https://manigoservices.atlassian.net/browse/PDK-6178
    // {
    //     icon: MdSupport,
    //     label: t('common:appUi.mainMenuItems.faq.text'),
    //     onClick: () => dispatchShowModal({
    //         modalType: ModalName.FAQ,
    //         modalProps: {
    //             modalSize: 'lg',
    //             title: t('faq:screenTitle.heading1'),
    //             id: 'faq-modal',
    //         },
    //     }),
    //     id: 'nav-menu-item-faq',
    //     dataTestId: 'nav-menu-item-faq',
    // },
    {
        label: t('common:appUi.userDropdown.logout.text'),
        icon: MdPowerSettingsNew,
        confirmation: {
            confirmationHeader: t('common:appUi.userDropdown.logout.text'),
            confirmationQuestion: t('common:appUi.userDropdown.logout.confirmationQuestion'),
            onConfirm: () => dispatchClearCurrentUser(),
        },
        id: 'nav-menu-item-logout',
        dataTestId: 'nav-menu-item-logout',
    },
]);

export default { createUserDropdownItemsConfig };
