import { ConsentStatus, Uuid } from '@manigo/manigo-domain-typings';
import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';

import { Action } from 'models/meta/action';
import { ExternalLocation } from 'models/app/navigation';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    CLEAR_NEW_CONSENTS_MODE, SET_NEW_CONSENTS_MODE, SetNewConsentModePayload,
    CHANGE_CONSENT_STATUS, CHANGE_CONSENT_STATUS_SUCCESS, CHANGE_CONSENT_STATUS_FAILURE, ChangeConsentStatusPayload, UNAUTHORISED_DECLINE_CONSENT_REQUEST,
} from './actions.types';


export const newConsentReducerName = 'newConsent';

export interface NewConsentStateType {
    newConsentUuid?: Uuid;
    newConsentCallbackUrl?: string;
    redirectionUrl?: string;
    isLoadingChangeConsentStatus: boolean;
    isLoadingUnauthorisedDeclineConsentRequest: boolean;
    newConsentStatus?: ConsentStatus;
    showSuccessStep: boolean,
}

const initialState: NewConsentStateType = {
    newConsentUuid: undefined,
    newConsentCallbackUrl: undefined,
    isLoadingChangeConsentStatus: false,
    isLoadingUnauthorisedDeclineConsentRequest: false,
    newConsentStatus: undefined,
    showSuccessStep: false,
    redirectionUrl: undefined,
};


const createAccountBuilderCases = (builder:ActionReducerMapBuilder<NewConsentStateType>) => {
    builder
        .addCase(SET_NEW_CONSENTS_MODE, (state: NewConsentStateType, action: Action<SetNewConsentModePayload>) => {
            const { newConsentUuid, newConsentCallbackUrl } = action.payload;
            state.newConsentCallbackUrl = newConsentCallbackUrl;
            state.newConsentUuid = newConsentUuid;

        })
        .addCase(CHANGE_CONSENT_STATUS, (state: NewConsentStateType, action: Action<ChangeConsentStatusPayload>) => {
            state.newConsentStatus = action.payload.status;
            state.isLoadingChangeConsentStatus = true;
        })
        .addCase(CHANGE_CONSENT_STATUS_SUCCESS, (state: NewConsentStateType, action: Action<ExternalLocation>) => {
            state.showSuccessStep = true;
            state.isLoadingChangeConsentStatus = false;
            state.redirectionUrl = action.payload;
        })

        .addCase(CHANGE_CONSENT_STATUS_FAILURE, (state: NewConsentStateType) => {
            state.isLoadingChangeConsentStatus = false;
        })
        .addCase(UNAUTHORISED_DECLINE_CONSENT_REQUEST, (state: NewConsentStateType) => {
            state.isLoadingUnauthorisedDeclineConsentRequest = true;
        })

        .addCase(CLEAR_NEW_CONSENTS_MODE, (state: NewConsentStateType) => {
            return {
                ...initialState,
                newConsentCallbackUrl: state.newConsentCallbackUrl,
            };
        })
        .addCase(CLEAR_CURRENT_USER, (state: NewConsentStateType) => {
            return {
                ...initialState,
                newConsentCallbackUrl: state.newConsentCallbackUrl,
            };
        });
};

export default createReducer(initialState, createAccountBuilderCases);
