import React from 'react';
import { connect } from 'react-redux';

import { WithAccessControl } from 'models/domain/accessControl';
import { getPropObj } from 'utils/object-tools';
import { currentUserReducerName } from 'store/current-user/reducer';
import { RootState } from 'store/rootReducer';


const mapPermissionNameToObjectPath = (permissionName): string => permissionName.replace(/:/g, '.');


function createAccessControlObject(state: RootState): WithAccessControl {
    const permissions = state[currentUserReducerName].permissions || { };


    return {
        accessControl: {
            isAuthorised: !!state[currentUserReducerName].jwtToken,

            hasPermission: (permissionName): boolean => {
                const permissionNameAsPath = mapPermissionNameToObjectPath(permissionName);
                const permissionValue = getPropObj(permissions, permissionNameAsPath);
                return permissionValue === true;
            },
            hasPermissions: (permissionNames): boolean => {
                return permissionNames.every((permissionName) => {
                    const permissionNameAsPath = mapPermissionNameToObjectPath(permissionName);
                    const permissionValue = getPropObj(permissions, permissionNameAsPath);
                    return permissionValue === true;
                });
            },

            hasOneOfPermissions: (permissionNames): boolean => {
                return permissionNames.some((permissionName) => {
                    const permissionNameAsPath = mapPermissionNameToObjectPath(permissionName);
                    const permissionValue = getPropObj(permissions, permissionNameAsPath);
                    return permissionValue === true;
                });
            },
        },

    };
}


export default function withAccessControl(WrappedComponent: React.ComponentType) {
    function AccessControlInjectorComponent(props) {
        return <WrappedComponent {...props} />;
    }

    const mapStateToProps = (state: RootState) => createAccessControlObject(state);

    return connect(mapStateToProps)(AccessControlInjectorComponent);
}
