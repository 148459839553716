import { ConsentDetails, ConsentScope } from '@manigo/manigo-domain-typings';
import React from 'react';
import { TFunction } from 'i18next';
import { isValidIBAN } from 'ibantools';
import { HStack } from '@chakra-ui/react';

import { TableColumnAlign } from 'models/app/tableColumnsConfiguration';
import { StandardValueType } from 'models/app/standardValues';
import { humanFriendlyPermissionName } from 'utils/string-tools';
import StandardValue from 'components/common/molecules/StandardValue';
import StandardLink from 'components/common/atoms/StandardLink';
import CurrencyRenderer from 'components/common/molecules/CurrencyRenderer';

import Styled from './ConsentDetailsDisplay.styled';

const createNewConsentDetailsFieldsConfig = ({ t }: { t: TFunction }) => {
    return [
        {
            title: t('consent:consentFields.tppName.text'),
            dataIndex: 'tppName',
            gridItemConfig: { colSpan: { base: 12 } },
            align: TableColumnAlign.right,
            render: (value) => <StandardValue value={value} />,
        },
        {
            title: t('consent:consentFields.tppWebsite.text'),
            dataIndex: 'tppWebsite',
            gridItemConfig: { colSpan: { base: 12 } },
            align: TableColumnAlign.right,
            render: (value) => <StandardLink title={value} onClick={() => window.open(value)} />,
        },
        {
            title: t('consent:consentFields.appName.text'),
            dataIndex: 'appName',
            gridItemConfig: { colSpan: { base: 12 } },
            align: TableColumnAlign.right,
            render: (value) => <StandardValue value={value} />,
        },
        {
            title: t('consent:consentFields.appDescription.text'),
            dataIndex: 'appDescription',
            gridItemConfig: { colSpan: { base: 12 } },
            align: TableColumnAlign.right,
            render: (value) => <StandardValue value={value} />,
        },
        {
            title: t('consent:consentFields.consentRequestCreated.text'),
            dataIndex: 'consentRequestCreated',
            gridItemConfig: { colSpan: { base: 12 } },
            align: TableColumnAlign.right,
            render: (value) => <StandardValue value={value} valueType={StandardValueType.DATE} showTime />,
        },
    ];
};

const createNewConsentPaymentInformationFieldsConfig = ({ t }: { t: TFunction }) => {
    return [
        {
            title: t('consent:consentFields.paymentInformation.creditorAccount.name.text'),
            dataIndex: 'paymentInformation.creditorAccount.name',
            gridItemConfig: { colSpan: { base: 12 } },
            align: TableColumnAlign.right,
            render: (value) => <StandardValue value={value} />,
        },
        {
            title: t('consent:consentFields.paymentInformation.creditorAccount.identification.text'),
            dataIndex: 'paymentInformation.creditorAccount.identification',
            gridItemConfig: { colSpan: { base: 12 } },
            align: TableColumnAlign.right,
            render: (value) => <StandardValue value={value} valueType={isValidIBAN(value) ? StandardValueType.IBAN : StandardValueType.TEXT} />,
        },
        {
            title: t('consent:consentFields.paymentInformation.instructedAmount.text'),
            dataIndex: 'paymentInformation.instructedAmount.amount',
            gridItemConfig: { colSpan: { base: 12 } },
            align: TableColumnAlign.right,
            render: (value, record: ConsentDetails) => {
                return (
                    <HStack justifyContent={'flex-end'}>
                        <StandardValue value={value} valueType={StandardValueType.AMOUNT} />
                        <CurrencyRenderer
                            currencyIsoCode={`${record?.paymentInformation?.instructedAmount?.currency}`}
                            showFullName={false}
                            valueFirst
                        />
                    </HStack>
                );
            },
        },
        {
            title: t('consent:consentFields.paymentInformation.remittanceInformationReference.text'),
            dataIndex: 'paymentInformation.remittanceInformation.reference',
            gridItemConfig: { colSpan: { base: 12 } },
            align: TableColumnAlign.right,
            render: (value) => <StandardValue value={value} />,
        },
    ];
};

export const createPermissionsFieldsConfig = () => {
    return [
        {
            dataIndex: 'permissions',
            gridItemConfig: { colSpan: { base: 12 } },
            align: TableColumnAlign.right,
            render: (permissions) => (
                <Styled.PermissionsListWrapper>
                    <Styled.PermissionsList>
                        {permissions.map((permission) => (
                            <Styled.PermissionsListItem key={permission}>
                                <StandardValue value={humanFriendlyPermissionName(permission)} valueType={StandardValueType.TEXT} showTooltip={false} />
                            </Styled.PermissionsListItem>
                        ))}
                    </Styled.PermissionsList>
                </Styled.PermissionsListWrapper>
            ),
        },
    ];
};

export const createNewConsentDetailsSectionsConfig = ({ t, consentScope }: { t: TFunction, consentScope?: ConsentScope }) => {

    return [
        {
            cardTitle: t('consent:consentDetailsPage.screenTitle'),
            gridItemConfig: { colSpan: { base: 12 } },
            columnsConfiguration: createNewConsentDetailsFieldsConfig({ t }),
        },

        ...(consentScope === ConsentScope.accounts
            ? [
                {
                    cardTitle: t('consent:consentDetailsPage.sectionsHeadings.permissions'),
                    gridItemConfig: { colSpan: { base: 12 } },
                    columnsConfiguration: createPermissionsFieldsConfig(),
                }]
            : []
        ),
        ...(consentScope === ConsentScope.accounts
            ? [{
                cardTitle: t('consent:consentDetailsPage.sectionsHeadings.permissions'),
                gridItemConfig: { colSpan: { base: 12 } },
                columnsConfiguration: createPermissionsFieldsConfig(),
            }]
            : []
        ),

        ...(consentScope === ConsentScope.payments
            ? [{
                cardTitle: t('consent:consentDetailsPage.sectionsHeadings.paymentInformation'),
                gridItemConfig: { colSpan: { base: 12 } },
                columnsConfiguration: createNewConsentPaymentInformationFieldsConfig({ t }),
            }]
            : []
        ),
    ];
};
