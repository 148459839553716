import { createReducer } from '@reduxjs/toolkit';

import { Action } from 'history';
import { EnhancedLocation, NavigationDirectionTypes } from 'models/app/navigation';
import { getSessionStorageObjectItem, setSessionStorageObjectItem } from 'utils/browser-storage';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import { INIT_APPLICATION } from 'store/application/action.types';
import {
    BLOCK_NAVIGATION, HIDE_NAVIGATION_CONFIRMATION,
    NAVIGATION_CANCELED,
    PREFORM_NAVIGATION_BACK_SUCCESS,
    PREFORM_NAVIGATION_SUCCESS,
    REQUEST_NAVIGATION, SET_SEARCH_PARAM, SHOW_NAVIGATION_CONFIRMATION,
    UNBLOCK_NAVIGATION,
} from './actions.types';

export const navigationReducerName = 'navigation';

export interface NavigationStateType {
    readonly previousLocation?: EnhancedLocation;
    readonly currentLocation: EnhancedLocation;
    readonly requestedNextLocation?: any;
    readonly blockNavigationReason?: any;

    readonly isNavigationConfirmationVisible: boolean;
}

const initialState: NavigationStateType = {
    previousLocation: undefined,
    currentLocation: {} as EnhancedLocation, // set on INIT_APPLICATION
    requestedNextLocation: undefined,

    blockNavigationReason: undefined,

    isNavigationConfirmationVisible: false,
};

const createNavigationBuilderCases = (builder) => builder
    .addCase(INIT_APPLICATION, (state) => {
        const pageReloaded = window.performance.getEntriesByType('navigation').map((nav) => nav.entryType.includes('reload'));
        const { location } = window;
        const storedLocation = getSessionStorageObjectItem('currentLocation');
        state.currentLocation = pageReloaded && storedLocation && storedLocation.pathname === location.pathname
            ? {
                ...getSessionStorageObjectItem('currentLocation'),
                key: 'PAGE_RELOAD',
            }
            : {
                hash: location.hash,
                key: pageReloaded ? 'PAGE_RELOAD' : 'INIT',
                pathname: location.pathname,
                // Enhance it to match data standard of history.location listener-driven location updates
                action: pageReloaded ? Action.Replace : Action.Push,
                direction: pageReloaded ? NavigationDirectionTypes.SELF : NavigationDirectionTypes.FORWARD,
            };
    })
    .addCase(SET_SEARCH_PARAM, (state, { payload }) => {
        state.currentLocation = {
            ...state.currentLocation,
            search: payload,
        };
        setSessionStorageObjectItem('currentLocation', { ...state.currentLocation });
    })
    .addCase(BLOCK_NAVIGATION, (state, action) => {
        state.blockNavigationReason = action.meta;
    })
    .addCase(UNBLOCK_NAVIGATION, (state) => {
        state.blockNavigationReason = undefined;
    })
    .addCase(REQUEST_NAVIGATION, (state, action) => {
        state.requestedNextLocation = {
            location: action.payload,
            meta: action.meta,
        };
    })
    .addCase(NAVIGATION_CANCELED, (state) => {
        state.requestedNextLocation = undefined;
    })
    .addCase(PREFORM_NAVIGATION_SUCCESS, (state, action) => {
        state.previousLocation = state.currentLocation;
        state.currentLocation = action.payload;
    })
    .addCase(PREFORM_NAVIGATION_BACK_SUCCESS, (state, action) => {
        state.previousLocation = state.currentLocation;
        state.currentLocation = action.payload;
    })
    .addCase(SHOW_NAVIGATION_CONFIRMATION, (state) => {
        state.isNavigationConfirmationVisible = true;
    })
    .addCase(HIDE_NAVIGATION_CONFIRMATION, (state) => {
        state.isNavigationConfirmationVisible = false;
    })
    .addCase(CLEAR_CURRENT_USER, () => initialState);

export default createReducer(initialState, createNavigationBuilderCases);
