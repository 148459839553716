import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';

import { Action } from 'models/meta/action';
import { ClearEntityDetailsPayload } from 'models/app/standardEntityDetails';
import { addLoadingState } from 'utils/redux-tools';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import { SET_QUERY_PARAMS } from 'store/application/action.types';


import {
    CLEAR_CONSENT_DETAILS,
    FETCH_CONSENT_DETAILS,
    FETCH_CONSENT_DETAILS_FAILURE,
    FETCH_CONSENT_DETAILS_SUCCESS,
    FetchConsentDetailsFailurePayload,
    FetchConsentDetailsPayload,
    FetchConsentDetailsSuccessPayload,
    REVOKE_CONSENT,
    REVOKE_CONSENT_FAILURE,
    REVOKE_CONSENT_SUCCESS,
    SetConsentDetailsQueryParamsPayload,
} from './actions.types';
import {
    updateStandardEntityDetails, updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from '../standardEntityDetails.helpers';

export const consentReducerName = 'consent';
export const consentDetailsPerLocationStoreKeyName = 'consentDetailsPerLocation';


const initialState = {
    [consentDetailsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    isLoadingRevokeConsent: false,
};

export type ConsentState = typeof initialState;


const createAccountBuilderCases = (builder:ActionReducerMapBuilder<ConsentState>) => {
    builder
        .addCase(FETCH_CONSENT_DETAILS, (state: ConsentState, action: Action<FetchConsentDetailsPayload>) => {
            state[consentDetailsPerLocationStoreKeyName] = updateStandardEntityDetails({
                entitiesDetailsState: state[consentDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_CONSENT_DETAILS_SUCCESS, (state: ConsentState, action: Action<FetchConsentDetailsSuccessPayload>) => {
            state[consentDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess({
                entitiesDetailsState: state[consentDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_CONSENT_DETAILS_FAILURE, (state: ConsentState, action: Action<FetchConsentDetailsFailurePayload>) => {
            state[consentDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure({
                entitiesDetailsState: state[consentDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(CLEAR_CONSENT_DETAILS, (state: ConsentState, action: Action<ClearEntityDetailsPayload>) => {
            state[consentDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup({
                entitiesDetailsState: state[consentDetailsPerLocationStoreKeyName],
                action,
            });
        })

        .addCase(SET_QUERY_PARAMS, (state: ConsentState, action: Action<SetConsentDetailsQueryParamsPayload>) => {
            if (action.payload?.reducerName === consentReducerName
                && action.payload?.fieldName === consentDetailsPerLocationStoreKeyName
            ) {
                state[consentDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({
                    entitiesDetailsState: state?.[consentDetailsPerLocationStoreKeyName],
                    action,
                });
            }
        })
        .addCase(CLEAR_CURRENT_USER, () => {
            return initialState;
        });
    addLoadingState([REVOKE_CONSENT, REVOKE_CONSENT_SUCCESS, REVOKE_CONSENT_FAILURE], 'isLoadingRevokeConsent', builder);
};

export default createReducer(initialState, createAccountBuilderCases);
