import React from 'react';
import { of } from 'rxjs';
import { BrowserHistory } from 'history';

import { Dependencies } from 'models/app/store';
import { sessionUuidKey } from 'config/config';
import { loginRootRoutePath, newConsentRootRoutePath } from 'config/routes';
import { getSessionStorageObjectItem, setSessionStorageObjectItem } from 'utils/browser-storage';
import { generateSessionUuid } from 'utils/session-uuid';
import { getJwtTokenFormStorage, removeJwtTokenFromStorage, setSessionUuid } from 'store/application/actions';
import { getLocalesManifest } from 'store/locales/actions';
import { setNewConsentMode } from 'store/new-consent/actions';
import AnimatedCheckMark from 'components/common/atoms/AnimatedCheckMark';


export function getIcon(type: string) {
    return type === 'success' ? { icon: <AnimatedCheckMark size={'24px'} /> } : {};
}


export function createAppInitActionsAndSideEffects(httpService: Dependencies['http'], history: BrowserHistory) {
    const existingSessionUuid = getSessionStorageObjectItem(sessionUuidKey);
    const newSessionUuid = generateSessionUuid();

    // @ts-expect-error type is valid!
    const pageDuplicated = window.performance.getEntriesByType('navigation').map((nav) => nav.type.includes('back_forward'));


    const setUuid = (uuid) => {
        httpService.setSessionUuid(uuid); // axios instance
        setSessionStorageObjectItem(sessionUuidKey, uuid); // session storage
    };

    const handlers = [
        { /* new consent use case - consentId in incoming URL */
            predicate: () => newConsentRootRoutePath === history.location.pathname,
            handler: () => {
                const params = new URLSearchParams(history.location.search);
                const newConsentUuid = `${params.get('ConsentId')}`;
                const newConsentCallbackUrl = `${params.get('CallbackURL')}`;

                // TODO WHY why like that ? why not via requestNavigateAction
                history.replace(loginRootRoutePath, history.location.state);
                setUuid(newSessionUuid);

                return of(
                    // XXX we remove JWT tokens in session storage in this case
                    removeJwtTokenFromStorage(),
                    getLocalesManifest(),
                    setSessionUuid(newSessionUuid),
                    setNewConsentMode({ newConsentUuid, newConsentCallbackUrl }),
                );
            },
        },
        { /* duplicated tab (except Safari & FF - new tab will be unauthorised) */
            predicate: () => existingSessionUuid && pageDuplicated && pageDuplicated[0],
            handler: () => {
                setUuid(newSessionUuid);
                return of(
                    getLocalesManifest(),
                    setSessionUuid(newSessionUuid),
                    getJwtTokenFormStorage(existingSessionUuid), // XXX with param!
                );
            },
        },
        { /*  tab reloaded or standard navigation caused by URL change */
            predicate: () => existingSessionUuid,
            handler: () => {
                setUuid(existingSessionUuid);
                return of(
                    getLocalesManifest(),
                    setSessionUuid(existingSessionUuid),
                    getJwtTokenFormStorage(),
                );
            },
        },

        { /* standard new tab */
            predicate: () => true,
            handler: () => {
                setUuid(newSessionUuid);
                return of(
                    setSessionUuid(newSessionUuid),
                    getLocalesManifest(),
                    getJwtTokenFormStorage(),
                );
            },
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}


export default { createAppInitActionsAndSideEffects };

