import dayjs from 'dayjs';
import React from 'react';

import { Container } from '@chakra-ui/react';
import { developmentMode, WL_CONFIG } from 'config/environment';
import Countdown from 'components/common/atoms/Countdown';

import StandardTranslationWithMarkup from '../StandardTranslationWithMarkup';
import Description from '../Description';

function CopyrightNotice({ t, accessTokenExpirationTimeStamp, namespace = 'common' }) {
    const currentYear = dayjs().year();
    return (
        <>
            <StandardTranslationWithMarkup
                translationKey={'copyrightNotice'}
                namespace={namespace}
                values={{
                    brandLandingPageUrl: WL_CONFIG.brandLandingPageUrl,
                    brandShortName: WL_CONFIG.brandShortName,
                    brandFullName: WL_CONFIG.brandFullName,
                    currentYear,
                }}
                t={t}
            />

            {accessTokenExpirationTimeStamp && developmentMode ? (
                <Container position={'absolute'} left={'50%'} top={'50%'} opacity={0.5} transform={'translate(-50%, -50%)'}>
                    <Description
                        label={t('Time to access token expiration')}
                        value={(
                            <Countdown
                                date={new Date(accessTokenExpirationTimeStamp)}
                                style={{
                                    justifyContent: 'center',
                                    gridTemplateColumns: 'repeat(2, auto)',
                                }}
                            />
                        )}
                        style={{
                            justifyContent: 'center',
                            gridTemplateColumns: 'repeat(2, auto)',
                        }}
                        gutterBottom={false}

                    />

                </Container>
            ) : <></>}
        </>
    );

}


export default CopyrightNotice;
