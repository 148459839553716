import { roundBig } from './math-tools';
import { UserPreferencesDecimalSeparators, UserPreferencesThousandsSeparators } from '../models/domain/userPreferencs';

const parseValue = (rawValue) => {
    const value = Number.parseFloat(rawValue);
    return value && !Number.isNaN(value) ? value : Number.NaN;
};

const formatNumberToNumberWithSeparatorsAndPrecision = ({
    rawValue,
    decimalSeparator = '.',
    thousandsSeparator = ',', // en-GB
    precision = 2,
}) => {
    const value = typeof rawValue === 'number'
        ? rawValue
        : parseValue(rawValue);


    const [stringNumsBeforeDot, stringNumsAfterDot = ''] = roundBig({
        value,
        precision,
        asString: true,
        roundingMode: 0,
    }).toString().split('.');

    const stringWithThousandSeparator = stringNumsBeforeDot.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, `$&${thousandsSeparator}`);
    return (
        stringNumsAfterDot
            ? stringWithThousandSeparator + decimalSeparator + stringNumsAfterDot
            : stringWithThousandSeparator
    );
};

export const amountFormatter = ({
    value,
    decimalSeparator,
    thousandsSeparator,
    precision = 2,
}:{
    value: number,
    decimalSeparator?: UserPreferencesDecimalSeparators,
    thousandsSeparator?: UserPreferencesThousandsSeparators,
    precision?: number,
}) => formatNumberToNumberWithSeparatorsAndPrecision({
    rawValue: value,
    decimalSeparator,
    thousandsSeparator,
    precision,
});
export const getAmountFieldBaseConfigs = () => ({
    min: 0,
    step: 10,
});

export default { amountFormatter };

