import { globalLocation, LocationPathname } from 'models/app/navigation';
import { StandardListData } from 'models/app/standardLists';
import { ListWatcher } from 'store/list/reducer';

export const calculateShouldUpdateList = <Items>({
    listWatchers,
    reducerName,
    listData,
    currentPathname,
}: {
    listWatchers: ListWatcher[],
    reducerName: string,
    listData?: StandardListData<Items>,
    currentPathname: LocationPathname,
}) => {
    const listWatcher = listWatchers?.find((item: ListWatcher) =>
        item.listReducerName === reducerName);
    if (!listWatcher) return false;

    const isItemExists = listData?.items?.find((item: any) => item[listWatcher.findByPropName] === listWatcher.findByPropValue);
    if (!isItemExists) return false;

    const { updates } = listWatcher;
    const isItemUpdated = updates?.find((update) => update.locationPathname === currentPathname);
    if (isItemUpdated) return false;

    return true;
};

export const getLocationHandlers = ({
    initialQueryParams,
    omitDataFetching,
    customLocation,
    currentPathname,
    useGlobalLocation,

    setAreInitialQueryParamsSet,
    setDoesListRequiresCleanup,
    setIsListReadyForDisplay,

    dispatchSetQueryParams,
    dispatchFetchItemsList,
    dispatchClearItemsList,
}) => ({
    handleEmptyStateOnNavForward: () => {
        setAreInitialQueryParamsSet(true);
        dispatchSetQueryParams({
            queryParams: initialQueryParams,
            locationPathname: useGlobalLocation ? globalLocation : customLocation || currentPathname,
        });
        if (!(omitDataFetching || useGlobalLocation)) {
            dispatchFetchItemsList({
                queryParams: initialQueryParams,
                locationPathname: useGlobalLocation ? globalLocation : customLocation || currentPathname,
            });
        }
    },
    handleExistingDataOnNavForward: () => {
        setAreInitialQueryParamsSet(true);

        if (omitDataFetching || useGlobalLocation) {
            setDoesListRequiresCleanup(false);
            setIsListReadyForDisplay(true);
            dispatchSetQueryParams({
                queryParams: initialQueryParams,
                locationPathname: useGlobalLocation ? globalLocation : customLocation || currentPathname,
            });
        } else {
            setDoesListRequiresCleanup(true);
            dispatchClearItemsList({ locationPathname: currentPathname });
            dispatchSetQueryParams({
                queryParams: initialQueryParams,
                locationPathname: useGlobalLocation ? globalLocation : customLocation || currentPathname,
            });
            dispatchFetchItemsList({
                queryParams: initialQueryParams,
                locationPathname: useGlobalLocation ? globalLocation : customLocation || currentPathname,
            });
        }
    },
    handleExistingDataOnNavBackward: () => {
        setAreInitialQueryParamsSet(true);
        setDoesListRequiresCleanup(false);
        setIsListReadyForDisplay(true);
    },
});

