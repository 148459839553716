import { AuthErrors } from 'models/api/auth';
import { LOGIN_COUNTDOWN_TIME } from './actions.types';
import { AuthorisationStateType } from './reducer.types';

const handleAuthFailure = (state: AuthorisationStateType, errorMessage) => {
    const initLoginFailureHandlers = [{
        predicate: () => errorMessage === AuthErrors.TEMPORARILY_BLOCKED_FOR || errorMessage === AuthErrors.TEMPORARILY_BLOCKED,
        handler: () => {
            state.loginBlockUntil = new Date(new Date().getTime() + LOGIN_COUNTDOWN_TIME);
            state.loginFormErrorMessage = errorMessage;
        },
    },
    {
        predicate: () => errorMessage === AuthErrors.USER_LOCKED,
        handler: () => {
            state.loginPermanentBlock = true;
        },
    },
    ];

    initLoginFailureHandlers.find((h) => h.predicate())?.handler();
};

export default handleAuthFailure;
