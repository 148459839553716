import { AuthErrors } from 'models/api/auth';
import { TFunction } from 'i18next';
import { RootState } from 'store/rootReducer';

const getLoginConfirmFormError = ({ loginConfirmFormError, t }:{
    loginConfirmFormError: RootState['authorisation']['loginConfirmFormError'],
    t: TFunction
}) => {
    const handlers = [
        {
            predicate: () => loginConfirmFormError === AuthErrors.INVALID_CREDENTIALS,
            handler: () => t('unauthorised:actionMessages.invalidPasscode'),
        },
        {
            predicate: () => loginConfirmFormError === AuthErrors.CONFIRMATION_TOKEN_EXPIRED,
            handler: () => t('unauthorised:actionMessages.confirmationTokenExpired'),
        },
        {
            predicate: () => loginConfirmFormError,
            handler: () => t('unauthorised:genericApiError'),
        },
        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];
    return handlers.find((handler) => handler.predicate())?.handler();
};

export default getLoginConfirmFormError;
