import queryString from 'query-string';
// eslint-disable-next-line no-restricted-imports
import i18next from 'i18next';

import { Action, Dispatch } from 'models/meta/action';
import { ApiError, ApiResponse, HttpMethod } from 'models/api/http';
import { baseApiUrl } from 'config/config';
import { instanceOwnerClientId } from 'config/environment';
import { convertKeysToCamelCase } from 'utils/convertKeysToCamelCase';
import { generateUuidV4 } from 'utils/id-tools';

import { createApiError } from './http.helpers';


class HttpServiceInstance {
    private readonly baseUrl;


    private readonly headers: Headers = new Headers({ 'Content-Type': 'application/json' });

    private userJwtToken: string | undefined;

    i18n?: typeof i18next;

    dispatch?: Dispatch;


    public constructor(baseUrl = baseApiUrl) {
        this.baseUrl = baseUrl;
        this.headers.set('client-id', instanceOwnerClientId);
    }


    public configure(dispatch: Dispatch, i18n: typeof i18next) {
        this.dispatch = dispatch;
        this.i18n = i18n;
        return this;
    }

    private handleResponseError(error) {
        if (error.handled) {
            error.actions.forEach((action) => this.dispatch ? this.dispatch(action) : undefined);
        }

        return Promise.reject(error);
    }

    private async request({
        method,
        url,
        data,
        config = {},
    }: {
        method: HttpMethod,
        url: string,
        config?: any,
        data?: any
    }): Promise<ApiResponse | ApiError> {
        const { download } = config;
        const responseType = download ? 'blob' : 'json';

        const queryParams = config?.params ? queryString.stringify(config?.params, { arrayFormat: 'bracket' }) : undefined;
        const buildUrl = (endpointURL, queryParamsString) => {
            return `${this.baseUrl}${endpointURL}${queryParamsString?.length > 0 ? `?${queryParamsString}` : ''}`;
        };

        this.headers.set('request-uuid', generateUuidV4());

        // XXX this is TMP
        if (config?.customHeader?.key && config?.customHeader?.value) {
            this.headers.set(config?.customHeader?.key, config?.customHeader?.value);
        }

        const response = await fetch(
            buildUrl(url, queryParams),
            {
                method,
                mode: 'cors',
                headers: this.headers,
                body: JSON.stringify(data),
            },
        );

        // XXX this is TMP
        if (this.headers.has(config?.customHeader?.key)) {
            this.headers.delete(config?.customHeader?.key);
        }

        const apiResponseObject = (responseType === 'blob' && response.ok) ? await response.blob() : await response.json();

        if (response.ok) {
            const parsedResponse = responseType === 'blob' ? apiResponseObject : convertKeysToCamelCase(apiResponseObject);
            return parsedResponse;
        } else {
            return this.handleResponseError(await createApiError({ response, config, apiResponseObject }, this.i18n));
        }
    }


    // public methods
    public get(url: string, config?: object): Promise<any> {
        return this.request({ method: 'get', url, data: undefined, config });
    }

    public delete(url: string, data?: object, config?: object): Promise<any> {
        return this.request({ method: 'delete', url, data, config });
    }

    public put(url: string, data?: object, config?: object): Promise<any> {
        return this.request({ method: 'put', url, data, config });
    }

    public post(url: string, data?: object, config?: object): Promise<any> {
        return this.request({ method: 'post', url, data, config });
    }

    public patch(url: string, data?: object, config?: object): Promise<any> {
        return this.request({ method: 'PATCH', url, data, config });
    }

    public setUserToken(rawJwtToken?: string) {
        this.userJwtToken = rawJwtToken;
        if (rawJwtToken) {
            this.headers.set('Authorization', `Bearer ${rawJwtToken}`);
        }
    }

    public clearUserToken() {
        this.userJwtToken = undefined;
        this.headers.delete('Authorization');
    }

    public setSessionUuid(sessionUuid: string) {
        this.headers.set('session-uuid', sessionUuid);
    }

    public storeDispatch(action: Action) {
        return this.dispatch ? this.dispatch(action) : undefined;
    }
}


export const HttpService = new HttpServiceInstance();

export interface HttpServiceStore {
    setUserToken: HttpServiceInstance['setUserToken']
    clearUserToken: HttpServiceInstance['clearUserToken']
    setSessionUuid: HttpServiceInstance['setSessionUuid']
    storeDispatch: HttpServiceInstance['storeDispatch']
}

export const createHttpService = (): HttpServiceStore => ({
    setUserToken: HttpService.setUserToken.bind(HttpService),
    clearUserToken: HttpService.clearUserToken.bind(HttpService),
    setSessionUuid: HttpService.setSessionUuid.bind(HttpService),
    storeDispatch: HttpService.storeDispatch.bind(HttpService),
});
