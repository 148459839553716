import { jwtTokenKey } from 'config/config';
import { getSessionStorageObjectItem, setSessionStorageObjectItem, removeSessionStorageObjectItem } from 'utils/browser-storage';


export const saveJwt = (tokensData) => {
    setSessionStorageObjectItem(jwtTokenKey, tokensData);
};

export const clearJwt = () => {
    removeSessionStorageObjectItem(jwtTokenKey);
};

export const getJwt = () => getSessionStorageObjectItem(jwtTokenKey);

export const isDateInTheFuture = (date, buffer = 15) => {
    const timestamp = typeof date === 'number' ? date : new Date(date).getTime();
    const safetyBuffer = buffer * 1000; // to milliseconds
    const now = new Date().getTime();
    return timestamp > now + safetyBuffer;
};


export const calculateTimeToSilentTokenRefresh = (expirationTime, buffer = 15) => {
    const validTill = typeof expirationTime === 'number' ? expirationTime : new Date(expirationTime).getTime();
    const safetyBuffer = buffer * 1000;
    const now = new Date().getTime();
    const delay = validTill - now - safetyBuffer;
    return delay > 0 ? delay : 0;
};
