export const sortingDirectionMapper = (sortingOrder) => {
    if (sortingOrder === 'ascend') {
        return 'asc';
    }

    return 'desc';
};


export const capitalizeFirstLetter = (string) => {
    if (string && string.length > 0) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return '';
};
export function humanFriendlyPermissionName(input: string): string {
    return input
        // Insert a space before capital letters that are not followed by another capital letter (to preserve acronyms)
        .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
        // Insert a space before capital letters that are followed by lowercase letters
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // Trim any leading space, just in case
        .trim();
}


export const removeUnderscores = (string = '') => (string).replace(/_/g, ' ');

export const enumValueToReadableString = (ENUM_VALUE = '') => capitalizeFirstLetter(removeUnderscores(ENUM_VALUE.toLowerCase()));

export const searchCaseInsensitive = (itemValue, searchQuery) => {
    if (itemValue) {
        return itemValue?.toLowerCase().includes(searchQuery.toLowerCase());
    }
    return false;
};


export default {
    sortingDirectionMapper,
    capitalizeFirstLetter,
    enumValueToReadableString,
    removeUnderscores,
    searchCaseInsensitive,
};
