import { ofType } from 'redux-observable';
import { Uuid } from '@manigo/manigo-domain-typings';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { ToastType } from 'models/app/toast';
import { Epic } from 'models/meta/epic';
import { Action } from 'models/meta/action';
import { normaliseTellMoneyDates } from 'utils/date-time-tools';
import { updateList } from 'store/list/actions';
import { consentsReducerName } from 'store/consents/reducer';
import { showToast } from 'store/application/actions';

import { FETCH_CONSENT_DETAILS, FetchConsentDetailsPayload, REVOKE_CONSENT, RevokeConsentPayload } from './actions.types';
import { fetchConsentDetailsSuccess, revokeConsentFailure, revokeConsentSuccess } from './actions';
import { handleGetConsentErrors } from './epics.helpers';


export const onFetchConsentDetails: Epic = (action$, state$, { consents }) => action$.pipe(
    ofType(FETCH_CONSENT_DETAILS),
    switchMap(({ payload }: Action<FetchConsentDetailsPayload>) => from(consents.fetchConsentDetails(payload.queryParams.consentUuid as Uuid))
        .pipe(
            switchMap((response) => of(fetchConsentDetailsSuccess({
                responsePayload: normaliseTellMoneyDates(response.data),
                locationPathname: payload.locationPathname,
            }))),
            catchError((error) => of(
                ...handleGetConsentErrors({
                    error,
                    newConsentMode: !!state$.value.newConsent.newConsentUuid,
                    locationPathname: payload.locationPathname,
                }),
            )),
        )),
);

export const onRevokeConsent: Epic = (action$, state$, { consents, i18n: { t } }) => action$.pipe(
    ofType(REVOKE_CONSENT),
    switchMap(({ payload }: Action<RevokeConsentPayload>) => from(consents.revokeConsent(payload.consentUuid))
        .pipe(
            switchMap(() => {
                return of(
                    showToast({
                        message: t('consent:actionMessages.revokeConsentSuccess'),
                        type: ToastType.success,
                    }),
                    revokeConsentSuccess(),
                    updateList({
                        findByPropName: 'consentId',
                        findByPropValue: payload.consentUuid,
                        listReducerName: consentsReducerName,
                    }),
                );
            }),
            catchError(() => of(revokeConsentFailure())),
        )),
);

export default [
    onRevokeConsent,
];

