import React, { useMemo, JSX } from 'react';

import { RouteConfiguration } from 'models/app/navigation';
import {
    consentHasExpiredRootRoutePath, consentNotFoundRootRoutePath,
    loginRootRoutePath,
    passcodeConfirmRootRoutePath,
} from 'config/routes';

import PublicLayout from 'components/App/layouts/PublicLayout';
import LoginPage from 'components/pages/unauthorised/LoginPage';
import ConsentHasExpired from 'components/pages/unauthorised/ConsentHasExpired';
import ConsentNotFound from 'components/pages/unauthorised/ConsentNotFound';
import PasscodeConfirmPage from 'components/pages/unauthorised/PasscodeConfirmPage';

import { renderRoutes } from '../ApplicationContent.helpers';
import { AnimatedRoutes } from '../AnimatedRoutes/AnimatedRoutes.layout';

function createPublicRoutesConfiguration(): RouteConfiguration[] {
    return [
        {
            path: loginRootRoutePath,
            element: <LoginPage />,
        },
        {
            path: passcodeConfirmRootRoutePath,
            element: <PasscodeConfirmPage />,
        },
        {
            path: consentHasExpiredRootRoutePath,
            element: <ConsentHasExpired />,
        },
        {
            path: consentNotFoundRootRoutePath,
            element: <ConsentNotFound />,
        },
    ];
}


function Public({ accessControl, enhancedCurrentLocation }): JSX.Element {
    const publicRoutesConfiguration = createPublicRoutesConfiguration();
    const defaultRedirectionPath = loginRootRoutePath;

    const publicRoutes = useMemo(
        () => renderRoutes({ routesConfiguration: publicRoutesConfiguration }),
        [publicRoutesConfiguration, accessControl],
    );


    return (
        <PublicLayout>
            <AnimatedRoutes
                routesConfiguration={publicRoutes}
                defaultRedirectionPath={defaultRedirectionPath}
                location={enhancedCurrentLocation}
            />
        </PublicLayout>
    );
}


export default Public;

