import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';

import { Dispatch } from 'models/meta/action';
import { RootState } from 'store/rootReducer';
import { localesReducerName } from 'store/locales/reducer';
import { loadLanguage } from 'store/locales/actions';

import { LanguageSelectorDispatchProps, LanguageSelectorStateProps } from './LanguageSelector.types';
import LanguageSelector from './LanguageSelector.layout';


const mapStateToProps = (state: RootState): LanguageSelectorStateProps => ({ selectedLocale: state[localesReducerName].selectedLocale });

const mapDispatchToProps = (dispatch: Dispatch): LanguageSelectorDispatchProps => ({
    dispatchLoadLanguage: (locale: string) => dispatch(loadLanguage(locale)),
    //
});

const decorate = compose<ComponentType>(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(LanguageSelector);
