import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';

import { Dispatch } from 'models/meta/action';
import { confirmLogin } from 'store/authorisation/actions';
import { requestNavigation } from 'store/navigation/actions';
import { RootState } from 'store/rootReducer';

import { authorisationReducerName } from 'store/authorisation/reducer';
import PasscodeConfirmPage from './PasscodeConfirmPage.layout';
import { PasscodeConfirmPageDispatchProps, PasscodeConfirmPageStateProps } from './PasscodeConfirmPage.types';

const mapStateToProps = (state: RootState): PasscodeConfirmPageStateProps => ({
    loginConfirmationValues: state[authorisationReducerName].loginConfirmationValues,
    loginRequestId: state[authorisationReducerName].loginRequestId,
    loginToken: state[authorisationReducerName].loginToken,
    submittedLoginField: state[authorisationReducerName].submittedLoginField,

    loginConfirmFormError: state[authorisationReducerName].loginConfirmFormError,
    isLoadingLoginConfirmationForm: state[authorisationReducerName].isLoadingLoginConfirmationForm,
});

const mapDispatchToProps = (dispatch: Dispatch): PasscodeConfirmPageDispatchProps => ({
    dispatchConfirmLogin: (data) => dispatch(confirmLogin(data)),
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),
});


const decorate = compose<ComponentType>(
    withTranslation('unauthorised'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(PasscodeConfirmPage);
