import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';

import { Dispatch } from 'models/meta/action';
import withAccessControl from 'hoc/withAccessControl';
import { changeConsentStatus } from 'store/new-consent/actions';
import { RootState } from 'store/rootReducer';
import { currentUserReducerName } from 'store/current-user/reducer';
import { newConsentReducerName } from 'store/new-consent/reducer';

import { NewConsentButtonsDispatchProps, NewConsentButtonsOwnProps, NewConsentButtonsStateProps } from './NewConsentButtons.types';
import NewConsentButtons from './NewConsentButtons.layout';


const mapStateToProps = (state: RootState): NewConsentButtonsStateProps => ({
    extUserUuid: state[currentUserReducerName]?.userData?.identifiers?.extUserUuid,
    newConsentUuid: state[newConsentReducerName].newConsentUuid,
    isLoadingChangeConsentStatus: state[newConsentReducerName].isLoadingChangeConsentStatus,
    newConsentStatus: state[newConsentReducerName].newConsentStatus,
});

const mapDispatchToProps = (dispatch: Dispatch): NewConsentButtonsDispatchProps => ({
    dispatchChangeConsentStatus: (requestPayload) => dispatch(changeConsentStatus(requestPayload)),
    //
});


const decorate = compose<ComponentType<NewConsentButtonsOwnProps>>(
    withTranslation('consent'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(NewConsentButtons);
