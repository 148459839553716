import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import i18next from 'i18next'; // legit use as epics middleware dependency
import { createBrowserHistory } from 'history';
import { Dependencies } from 'models/app/store';
import initializeSentry from 'config/sentry';
import { getAppVersion, debugMode } from 'config/environment';
import { createHttpService, HttpService } from 'services/http';
import AuthorisationService from 'services/authorisation/AuthorisationService';
import LocationService from 'services/location/LocationService';
import CountriesService from 'services/countries/CountriesService';
import CurrenciesService from 'services/currencies/CurrenciesService';
import ConsentsService from 'services/consents/ConsentsService';
import { createStore } from 'store/createStore';
import { initApplication } from 'store/application/actions';
import Main from 'components/App/Main';
import CustomRouter from 'components/App/CustomRouter.layout';

import { browserWindow } from './models/meta/window';


browserWindow.applicationVersionData = () => {
    // eslint-disable-next-line no-console
    console.dir(getAppVersion());
};

const wrapper = document.getElementById('root') as HTMLDivElement;
const root = createRoot(wrapper!);

const http = createHttpService();

const history = createBrowserHistory({ window });
const location = new LocationService(history, window.location);

// error tracker
initializeSentry(history);
// redux-observables (RxJS) middleware dependencies
const dependencies: Dependencies = {
    document,
    history,
    i18n: i18next,
    http,
    location,

    authorisation: new AuthorisationService(),
    countries: new CountriesService(),
    currencies: new CurrenciesService(),
    consents: new ConsentsService(),
};

// redux store initialisation
const { store } = createStore(dependencies);

export type StoreDispatch = typeof store.dispatch;

store.dispatch(initApplication());
HttpService.configure(store.dispatch, i18next);


const main = debugMode
    ? <React.StrictMode><Main /></React.StrictMode>
    : <Main />;


root.render(
    <>
        <Provider store={store}>
            <CustomRouter history={history}>
                {main}
            </CustomRouter>
        </Provider>
    </>,
);
