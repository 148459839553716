import { Input } from '@chakra-ui/react';
import React, { ClipboardEventHandler } from 'react';

import noop from 'utils/function-tools';

import { onInputKeyDown } from 'components/common/molecules/DigitsCodeInput/DigitsCodeInput.helpers';
import { DigitsCodeInputDispatchProps, FieldConfig } from '../DigitsCodeInput.types';


function DigitInput({
    hasError,
    dispatchSetPasscodeDigit,
    dispatchClearAllPasscodeDigits,
    formItem,
    value,
    onPaste,
}: {
    hasError: boolean,
    formItem: FieldConfig,
    value: string,
    dispatchSetPasscodeDigit: DigitsCodeInputDispatchProps['dispatchSetPasscodeDigit'],
    dispatchClearAllPasscodeDigits: DigitsCodeInputDispatchProps['dispatchClearAllPasscodeDigits'],
    onPaste: ClipboardEventHandler<HTMLInputElement>,
}) {
    const onKeyDown = (event) => onInputKeyDown({
        event,
        formItem,
        dispatchSetPasscodeDigit,
    });


    return (
        <Input
            data-test-id={formItem.dataTestId}
            isInvalid={hasError}
            placeholder={'◈'}
            ref={formItem.ownRef}
            autoFocus={formItem.autoFocus}
            type="text"
            value={value}
            onKeyDown={onKeyDown}
            onPaste={onPaste}
            onChange={noop}
            onFocus={() => (hasError
                ? dispatchClearAllPasscodeDigits()
                : undefined)}
            width={'12'}
            height={'12'}

        />
    );
}

export default DigitInput;
