import { useEffect, useMemo, useState } from 'react';
import { EnhancedLocation, LocationPathname, NavigationDirectionTypes } from 'models/app/navigation';
import {
    FetchEntityDetailsPayload,
    StandardEntityDetailsData,
} from 'models/app/standardEntityDetails';
import { DispatchSetQueryParamsPayload } from 'models/app/queryParams';

export interface UseStandardDetailsPageCachingLogic<Item, EnforcedQP> {
    locationValidatorString?: LocationPathname,
    contextEnforcedQueryParams: EnforcedQP,

    enhancedCurrentLocation: EnhancedLocation,
    entityDetailsData: StandardEntityDetailsData<Item>,

    dispatchFetchEntityDetails: ({ queryParams, locationPathname }: FetchEntityDetailsPayload<any>) => void,
    dispatchClearEntityDetails: ({ locationPathname }: { locationPathname: string }) => void,
    dispatchSetQueryParams: ({ queryParams, locationPathname }: DispatchSetQueryParamsPayload<EnforcedQP>) => void,
}

export function useStandardDetailsPageCachingLogic<Item, EnforcedQP>({
    locationValidatorString = '',
    enhancedCurrentLocation,

    contextEnforcedQueryParams,
    entityDetailsData,

    dispatchFetchEntityDetails,
    dispatchClearEntityDetails,
    dispatchSetQueryParams,
}: UseStandardDetailsPageCachingLogic<Item, EnforcedQP>) {
    const locationPathname = enhancedCurrentLocation.pathname;

    const [areInitialQueryParamsSet, setAreInitialQueryParamsSet] = useState(false);
    const [isDetailsPageReadyForDisplay, setIsDetailsPageReadyForDisplay] = useState(false);
    const [triggerLocationHandlers, setTriggerLocationHandlers] = useState(false);

    // InitialQuery === default query params + query params enforced by context/props
    const initialQueryParams = { ...contextEnforcedQueryParams };

    const hasValidLocation = useMemo(
        () => locationPathname?.length > 0 && locationPathname.includes(locationValidatorString),
        [locationPathname],
    );


    const isEntityDetailsEmpty = useMemo(
        () => !entityDetailsData?.entityDetails,
        [entityDetailsData],
    );

    const onLocationChangeHandlers = [
        // no location === no details fetching because at least ID/UUID is mandatory (possibly with other optional query params)
        // and it comes form URL params
        {
            predicate: () => !hasValidLocation && !(Object.keys(contextEnforcedQueryParams || {})?.length > 0),
            handler: () => {
                return undefined;
            },
        },
        // returning to on given location via forward navigation
        // & clear existing data for given location and fetch new with initialQueryParams, spinner ON
        {
            predicate: () => hasValidLocation
                && !isEntityDetailsEmpty
                && enhancedCurrentLocation?.direction === NavigationDirectionTypes.FORWARD,
            handler: () => {
                const payload = { queryParams: initialQueryParams, locationPathname };
                dispatchClearEntityDetails({ locationPathname });
                setAreInitialQueryParamsSet(true);
                dispatchSetQueryParams(payload);
                dispatchFetchEntityDetails(payload);
            },
        },
        // returning to on given location via go back navigation, just display what is already there, spinner OFF
        {
            predicate: () => hasValidLocation
                && !isEntityDetailsEmpty
                && enhancedCurrentLocation?.direction === NavigationDirectionTypes.BACKWARD,
            handler: () => {
                setIsDetailsPageReadyForDisplay(true);
            },
        },

        // 1st time on given location, set queryParams and fetch data, spinner ON
        {
            predicate: () => hasValidLocation
                && isEntityDetailsEmpty
                && !areInitialQueryParamsSet,
            handler: () => {
                const payload = { queryParams: initialQueryParams, locationPathname };
                dispatchSetQueryParams({
                    queryParams: initialQueryParams,
                    locationPathname,
                });
                dispatchFetchEntityDetails(payload);
                setAreInitialQueryParamsSet(true);
            },
        },
        // default fallback
        {
            predicate: () => true,
            handler: () => {
                return undefined;
            },
        },
    ];

    const onEntityDetailsDataChangeHandlers = [
        // 1st time on given location - PART TWO, data was fetched, spinner OFF
        {
            predicate: () => hasValidLocation
                && !isEntityDetailsEmpty
                && areInitialQueryParamsSet
                && !entityDetailsData?.isLoadingDetails, // XXX important
            handler: () => {
                setIsDetailsPageReadyForDisplay(true);
            },
        },
        // returning to on given location via forward navigation - PART II (after clearing, new data has arrived), spinner OFF
        {
            predicate: () => hasValidLocation
                && !isEntityDetailsEmpty
                && areInitialQueryParamsSet
                && !entityDetailsData?.isLoadingDetails, // XXX important
            handler: () => {
                setIsDetailsPageReadyForDisplay(true);
            },
        },
        {
            predicate: () => hasValidLocation
                && isEntityDetailsEmpty,
            handler: () => {
                setIsDetailsPageReadyForDisplay(false);
            },
        },
        // default fallback
        {
            predicate: () => true,
            handler: () => {
                return undefined;
            },
        },
    ];

    // For entity ID change case, but component not destructed
    useEffect(() => {
        setAreInitialQueryParamsSet(false);
        setIsDetailsPageReadyForDisplay(false);
        setTriggerLocationHandlers(!triggerLocationHandlers);
    }, [locationPathname]);

    useEffect(() => {
        onLocationChangeHandlers.filter(({ predicate }) => predicate())[0].handler();
    }, [triggerLocationHandlers]);

    useEffect(() => onEntityDetailsDataChangeHandlers.filter(({ predicate }) => predicate())[0].handler(), [entityDetailsData]);

    return isDetailsPageReadyForDisplay && !isEntityDetailsEmpty;
}

export default useStandardDetailsPageCachingLogic;
