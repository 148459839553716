import { AllStringValuesFormNestedObject } from 'models/meta/allStringValuesFormNestedObject';
import { flattenObject } from 'utils/object-tools';

// PermissionsNames -> PN
export const PN = Object.freeze(
    {
        AUTH: { ACTIVITY: 'auth:activity' },
        CURRENCIES: { READ: 'currencies:read' },
        OPEN_BANKING: {
            GET_CONSENT_DETAILS_BY_ID: 'open-banking:get-consent-details-by-id',
            GET_USER_CONSENTS: 'open-banking:get-user-consents',
            REVOKE_CONSENT_BY_ID: 'open-banking:revoke-consent-by-id',
        },
    },
);
export type PermissionNames = AllStringValuesFormNestedObject<typeof PN>;


export const permissionSetMinRequiredByBOP = [
    PN.CURRENCIES.READ,
];


const flatPN = flattenObject(PN);
type pnKeys = keyof typeof flatPN;

export type PermissionName = typeof flatPN[pnKeys];

export default { PN };
