import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';

import { Dispatch } from 'models/meta/action';
import { requestNavigation } from 'store/navigation/actions';

import { DefaultRedirectionDispatchProps } from './DefaultRedirection.types';
import DefaultRedirection from './DefaultRedirection.layout';


const mapDispatchToProps = (dispatch: Dispatch): DefaultRedirectionDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),
    //
});

const decorate = compose(
    connect(null, mapDispatchToProps),
);

export default decorate(DefaultRedirection);
