import { defaultQueryParamsMeta, updateQueryParamsMeta } from 'models/app/applicationState';
import { globalLocation } from 'models/app/navigation';
import { StandardListData } from 'models/app/standardLists';
import { navigationReducerName } from 'store/navigation/reducer';

export const standardListStateDataPlaceholder = {
    items: undefined,
    totalCount: undefined,
    isLoadingList: false,
    queryParams: {
        sortBy: undefined,
        sortOrder: undefined,
    },
    queryParamsMeta: defaultQueryParamsMeta,
};


export function updateStandardLists({ listsState = {}, action }) {
    const locationPathname = action.payload?.locationPathname;
    const queryParamsMeta = updateQueryParamsMeta();
    return {
        ...listsState,
        [locationPathname]: {
            ...listsState[locationPathname],
            // XXX suspicious fix!
            // if there is now query params and fetch request has them, add them
            ...(!listsState[locationPathname]?.queryParams && action?.payload?.queryParams ? { queryParams: action.payload.queryParams } : {}),
            queryParamsMeta,
            isLoadingList: true,
        },
    };
}

export function updateStandardListsOnSuccess({
    listsState = {},
    action,
}) {
    const queryParamsMeta = updateQueryParamsMeta();
    const locationPathname = action.payload?.locationPathname;

    if (locationPathname) {
        return {
            ...listsState,
            [locationPathname]: {
                ...listsState[locationPathname],
                queryParamsMeta,
                isLoadingList: false,
                items: action.payload?.items,
                totalCount: action.payload?.totalCount,
            },
        };
    }
    return listsState;
}

export function updateStandardListsOnFailure({ listsState = {}, action }) {
    const queryParamsMeta = updateQueryParamsMeta(true);
    const locationPathname = action.payload?.locationPathname;

    return {
        ...listsState,
        [locationPathname]: {
            ...listsState[locationPathname],
            queryParamsMeta,
            isLoadingList: false,
            items: undefined,
            totalCount: undefined,
        },
    };
}


export interface GetStandardListData {
    state: any,
    reducerName: string,
    listsByLocationStoreKeyName: string,
    useGlobalLocation?: boolean,
    locationSuffix?: string,
    customLocation?: string,
}

export function getStandardListData<ItemDefinition>({
    state,
    reducerName = '',
    listsByLocationStoreKeyName = '',
    useGlobalLocation = false,
    locationSuffix,
    customLocation,
}: GetStandardListData): StandardListData<ItemDefinition> {
    const finalDataLocation = useGlobalLocation
        ? globalLocation
        : customLocation || `${state?.[navigationReducerName]?.currentLocation?.pathname}${locationSuffix && locationSuffix.length > 0 ? locationSuffix : ''}`;

    const listData = state?.[reducerName]?.[listsByLocationStoreKeyName]?.[finalDataLocation];
    return listData || standardListStateDataPlaceholder;
}

export function getCurrentPathname(
    store = {},
) {
    return store?.[navigationReducerName]?.currentLocation?.pathname;
}

export function updateStandardListDataQueryParams({
    listsState = {},
    action,
}) {
    const { locationPathname } = action.payload;
    const listCurrentData = listsState[action.payload.locationPathname];

    if (locationPathname) {
        const currentData = listCurrentData || standardListStateDataPlaceholder;

        const resultData = {
            ...listsState,
            [locationPathname]: {
                ...currentData,
                queryParams: {
                    ...currentData?.queryParams,
                    ...action.payload.queryParams,
                },
            },
        };
        return resultData;
    }

    return listsState;
}


export function clearStandardListData({
    listsState = {},
    action,
}: { listsState: any, action: { payload: { locationPathname: string } } }) {
    const { [action.payload.locationPathname]: thisLocation, ...rest } = listsState;
    return { ...rest };
}

export function updateStandardListsOnCleanup({
    listsState = {},
    action,
}) {
    const { locationPathname } = action.payload;
    const existingData = listsState[locationPathname];

    if (existingData) {
        const {
            [locationPathname]: theKeyToBeExcluded,
            ...rest
        }: any = listsState;
        return rest;
    }

    return listsState;
}


export default {
    standardListStateDataPlaceholder,
    // map state to props store getter
    getStandardListData,
    getCurrentPathname,

    // standard 3 actions for API call + clear
    updateStandardLists,
    updateStandardListsOnSuccess,
    updateStandardListsOnFailure,
    updateStandardListsOnCleanup,

    // standard query params & navigation-triggered updates
    updateStandardListDataQueryParams,
    clearStandardListData,
};
