import { ofType } from 'redux-observable';
import { catchError, delay, mergeMap, switchMap } from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';

import { Epic } from 'models/meta/epic';
import { AuthErrors } from 'models/api/auth';
import { ConfirmLoginResponsePayload } from 'models/domain/authorisation';
import { permissionsKey, userPreferencesKey } from 'config/config';
import { loginRootRoutePath, passcodeConfirmRootRoutePath } from 'config/routes';
import { getSessionStorageObjectItem } from 'utils/browser-storage';
import { getJwt } from 'utils/jwtToken';
import { requestNavigation } from 'store/navigation/actions';
import { clearCurrentUser, setCurrentUser } from 'store/current-user/actions';
import {
    initialiseLoginSuccess,
    initialiseLoginFailure,
    confirmLoginSuccess,
    confirmLoginFailure,
    refreshTokenSuccess,
    refreshTokenFailure,
} from 'store/authorisation/actions';
import {
    CONFIRM_LOGIN, CONFIRM_LOGIN_FAILURE, ConfirmLoginPayload,
    GET_TOKENS_FOR_DUPLICATED_TAB,
    INITIALISE_LOGIN, InitialiseLoginPayload, REFRESH_TOKEN,
} from './actions.types';
import SET_CURRENT_USER_VARIANT from '../current-user/epics.helpers';


export const onInitialiseLogin: Epic = (action$, state$, { authorisation }) => {
    return action$.pipe(
        ofType(INITIALISE_LOGIN),
        switchMap(({ payload }: { payload: InitialiseLoginPayload }) => {
            const { login, loginType, password } = payload;
            return from(authorisation.loginInit({ login, loginType, password })).pipe(
                switchMap((response) => {
                    return of(
                        initialiseLoginSuccess(response?.data),
                        requestNavigation({ locationPathname: passcodeConfirmRootRoutePath }),
                    );
                }),
                catchError((error) => of(initialiseLoginFailure({
                    error,
                    login,
                }))),
            );
        }),
    );
};


export const onConfirmLogin: Epic = (action$, state$, { authorisation }) => {
    return action$.pipe(
        ofType(CONFIRM_LOGIN),
        switchMap(({ payload }: { payload: ConfirmLoginPayload }) => {
            return from(authorisation.loginConfirm(payload)).pipe(
                switchMap(({ data }: { data: ConfirmLoginResponsePayload }) => of(
                    confirmLoginSuccess(data),
                    setCurrentUser(data, SET_CURRENT_USER_VARIANT.LOGIN),
                )),
                catchError((error) => of(confirmLoginFailure(error.data?.message))),
            );
        }),
    );
};

export const onConfirmLoginFailure: Epic = (action$) =>
    action$.pipe(
        ofType(CONFIRM_LOGIN_FAILURE),
        delay(1000),
        switchMap(({ payload }) => {
            const errorMessage = payload;
            const shouldNavigateToLogin = errorMessage === AuthErrors.TEMPORARILY_BLOCKED_FOR
                || errorMessage === AuthErrors.TEMPORARILY_BLOCKED
                || errorMessage === AuthErrors.CONFIRMATION_TOKEN_EXPIRED
                || errorMessage === AuthErrors.USER_LOCKED;

            return shouldNavigateToLogin ? of(requestNavigation({ locationPathname: loginRootRoutePath })) : EMPTY;
        }),
    );


export const onGetTokensForDuplicatedTab: Epic = (action$, _, { authorisation }) => {
    return action$.pipe(
        ofType(GET_TOKENS_FOR_DUPLICATED_TAB),
        switchMap(({ payload }) => {
            return from(authorisation.getTokensForDuplicatedSession(payload.requestPayload))
                .pipe(
                    switchMap((response) => of(setCurrentUser({ ...response.data, ...payload.sessionStorageData }, SET_CURRENT_USER_VARIANT.DUPLICATE_TAB))),
                    catchError(() => of(clearCurrentUser())),
                );
        }),
    );
};

export const onRefreshToken = (action$, state$, { authorisation }) => action$.pipe(
    ofType(REFRESH_TOKEN),
    mergeMap(() => {
        const permissions = getSessionStorageObjectItem(permissionsKey);
        const jwtTokens = getJwt();
        const userPreferences = getSessionStorageObjectItem(userPreferencesKey);

        return from(authorisation.extendTokenValidity(jwtTokens.refreshToken)).pipe(
            switchMap((response: any) => of(
                refreshTokenSuccess(),
                setCurrentUser({
                    ...response.data,
                    permissions,
                    userPreferences,
                }, SET_CURRENT_USER_VARIANT.REFRESH_SESSION),
            )),
            catchError(() => of(refreshTokenFailure(), clearCurrentUser())),
        );
    }),
);


export default [
    onInitialiseLogin,
    onConfirmLogin,
    onConfirmLoginFailure,
    onGetTokensForDuplicatedTab,
];
