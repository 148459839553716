import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';

import { Dispatch } from 'models/meta/action';
import withAccessControl from 'hoc/withAccessControl';
import { newConsentHasExpired } from 'store/new-consent/actions';

import { NewConsentHeadingDispatchProps, NewConsentHeadingOwnProps } from './NewConsentHeading.types';
import NewConsentHeading from './NewConsentHeading.layout';


const mapDispatchToProps = (dispatch: Dispatch): NewConsentHeadingDispatchProps => ({
    dispatchNewConsentHasExpired: () => dispatch(newConsentHasExpired()),
    //
});


const decorate = compose<ComponentType<NewConsentHeadingOwnProps>>(
    withTranslation('consent'),
    withAccessControl,
    connect(null, mapDispatchToProps),
);

export default decorate(NewConsentHeading);
