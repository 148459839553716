import React, { JSX, useEffect } from 'react';
import { ConsentDetails } from '@manigo/manigo-domain-typings';

import { approveNewConsentRootRoutePath } from 'config/routes';
import useStandardDetailsPageCachingLogic from 'hook/useStandardDetailsPageCachingLogic';
import { isDateInTheFuture } from 'utils/jwtToken';
import TheSpinner from 'components/common/atoms/TheSpinner';
import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';

import { NewConsentPageProps } from './NewConsentPage.types';
import NewConsentForm from './NewConsentForm';
import SuccessWithRedirection from './SuccessWithRedirection';

function NewConsentPage({
    newConsentUuid,
    showSuccessStep,
    entityDetailsData,
    enhancedCurrentLocation,
    dispatchSetQueryParams,
    dispatchFetchConsentDetails,
    dispatchClearConsentDetails,
    dispatchNewConsentHasExpired,
}: NewConsentPageProps): JSX.Element {

    const contextEnforcedQueryParams = { consentUuid: newConsentUuid };

    const isPageReady = useStandardDetailsPageCachingLogic<ConsentDetails, any>({
        contextEnforcedQueryParams,
        enhancedCurrentLocation,
        entityDetailsData,
        dispatchFetchEntityDetails: dispatchFetchConsentDetails,
        dispatchClearEntityDetails: dispatchClearConsentDetails,
        dispatchSetQueryParams,
        locationValidatorString: approveNewConsentRootRoutePath,
    });

    useEffect(() => {
        if (entityDetailsData?.entityDetails?.consentRequestExpiry
            && !isDateInTheFuture(entityDetailsData?.entityDetails?.consentRequestExpiry)) {
            dispatchNewConsentHasExpired();
        }
    }, [entityDetailsData?.entityDetails?.consentRequestExpiry]);

    return (
        <StandardAuthorisedPage>
            <>
                {isPageReady && showSuccessStep
                    ? (
                        <SuccessWithRedirection entityDetailsData={entityDetailsData} />
                    ) : <></>}

                {isPageReady && !showSuccessStep
                    ? <NewConsentForm entityDetailsData={entityDetailsData} />
                    : <></>}

                {!isPageReady
                    ? <TheSpinner />
                    : <></>}
            </>
        </StandardAuthorisedPage>
    );
}


export default NewConsentPage;
