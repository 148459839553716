import { ofType } from 'redux-observable';
import { switchMap, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import { Epic } from 'models/meta/epic';


import { setSessionStorageObjectItem } from 'utils/browser-storage';
import {
    preformNavigation,
    preformNavigationBack,
} from 'store/navigation/actions';
import {
    BLOCK_NAVIGATION,
    PREFORM_NAVIGATION,
    PREFORM_NAVIGATION_BACK, PREFORM_NAVIGATION_BACK_SUCCESS,
    PREFORM_NAVIGATION_SUCCESS,
    REQUEST_NAVIGATION,
    REQUEST_NAVIGATION_BACK,
    UNBLOCK_NAVIGATION,
} from './actions.types';


export const onBlockNavigation: Epic = (action$, _, { location }) => action$.pipe(
    ofType(BLOCK_NAVIGATION),
    tap(({ payload }) => location.lock(payload)),
    switchMap(() => EMPTY),
);

export const onUnblockNavigation: Epic = (action$, _, { location }) => action$.pipe(
    ofType(UNBLOCK_NAVIGATION),
    tap(() => location.unlock()),
    switchMap(() => EMPTY),
);


export const onNavigationRequested:Epic = (action$) => {
    return action$.pipe(
        ofType(REQUEST_NAVIGATION),
        switchMap(({ payload, meta }) => {
            return of(preformNavigation({ locationPathname: payload, meta }));
        }),
    );
};
export const onNavigationBackRequested: Epic = (action$) => {
    return action$.pipe(
        ofType(REQUEST_NAVIGATION_BACK),
        switchMap(() => {
            return of(preformNavigationBack());
        }),
    );
};

export const onPreformNavigation: Epic = (action$, _, { location }) => {
    return action$.pipe(
        ofType(PREFORM_NAVIGATION),
        tap(({ payload, meta }) => location.navigate(payload, meta)),
        switchMap(() => EMPTY),
    );
};


export const onPreformNavigationBack: Epic = (action$, _, { location }) => {
    return action$.pipe(
        ofType(PREFORM_NAVIGATION_BACK),
        tap(() => location.goBack()),
        switchMap(() => EMPTY),
    );
};


export const onLocationChanged: Epic = (action$) => {
    return action$.pipe(
        ofType(
            PREFORM_NAVIGATION_SUCCESS,
            PREFORM_NAVIGATION_BACK_SUCCESS,
        ),
        tap(({ payload }) => {
            // TMP fix for missing location.state after page reload
            // eventually it will be resolved by redux-persist usage
            setSessionStorageObjectItem('currentLocation', { ...payload });
        }),
        switchMap(() => {
            return EMPTY;
        }),
    );
};


export default [
    onNavigationRequested,
    onPreformNavigation,
    onNavigationBackRequested,
    onPreformNavigationBack,
    onBlockNavigation,
    onUnblockNavigation,
    onLocationChanged,
];
