import Big from 'big.js';

// XXX Big.js rounding mode
// 0 - Rounds towards zero. I.e. truncate, no rounding. ROUND_DOWN

// XXX - default!!!
// 1 -Rounds towards nearest neighbour. If equidistant, rounds away from zero. ROUND_HALF_UP

// 2 - Rounds towards nearest neighbour. If equidistant, rounds towards even neighbour. ROUND_HALF_EVEN
// 3 - Rounds away from zero. ROUND_UP


export const subtractBig = ({
    x = 0,
    y = 0,
    precision = 2,
    asString = false,
    roundingMode = 1,
}) => {
    if ((!!Number(x) || x === 0) && (!!Number(y) || y === 0)) {
        Big.RM = roundingMode;
        const xBig = new Big(x);
        const result = xBig.minus(y);

        if (asString) {
            return result.toFixed(precision);
        }
        return Number(result.toFixed(precision));
    }
    return 0;
};

export const divideBig = ({
    x = 0,
    y,
    precision = 2,
    asString = false,
    roundingMode = 1,
}) => {
    if ((!!Number(x) || x === 0) && !!Number(y)) {
        Big.RM = roundingMode;

        const xBig = new Big(x);
        const result = xBig.div(y);

        if (asString) {
            return result.toFixed(precision);
        }
        return Number(result.toFixed(precision));
    }
    return 0;
};

export const multiplyBig = ({
    x = 0,
    y = 0,
    precision = 2,
    asString = false,
    roundingMode = 1,
}) => {
    if ((!!Number(x) || x === 0) && (!!Number(y) || y === 0)) {
        Big.RM = roundingMode;
        const xBig = new Big(x);
        const result = xBig.times(y);

        if (asString) {
            return result.toFixed(precision);
        }
        return Number(result.toFixed(precision));
    }
    return 0;
};


export const sumBig = ({
    x = 0,
    y = 0,
    precision = 2,
    asString = false,
    roundingMode = 1,
}) => {
    if ((!!Number(x) || x === 0) && (!!Number(y) || y === 0)) {
        Big.RM = roundingMode;
        const xBig = new Big(x);
        const result = xBig.plus(y);
        if (asString) {
            return result.toFixed(precision);
        }
        return Number(result.toFixed(precision));
    }
    return 0;
};


export const roundBig = ({
    value = 0,
    precision = 2,
    asString = false,
    roundingMode = 1,
}) => {
    if (Number(value)) {
        Big.RM = roundingMode;
        const valueBig = new Big(value);
        if (asString) {
            return valueBig.toFixed(precision);
        }
        return Number(valueBig.toFixed(precision));
    }
    return 0;
};
