import { Input, InputGroup } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { isNull, isUndefined, isEmptyString, isNaNValue } from 'utils/object-tools';

import { parseLocalizedStringNumberToNumber } from './StandardNumberInput.helpers';
import { StandardNumberInputProps } from './StandardNumberInput.types';

function StandardNumberInput({
    userPreferences,
    inputLeftElement,
    inputRightElement,
    fieldConfiguration,
}:StandardNumberInputProps) {
    const { decimalSeparator, thousandsSeparator } = userPreferences;
    const inputRef:React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null);
    const [inputContext, setInputContext] = React.useState({
        value: '',
        caretPosition: 0,
    });

    const formatNumber = (value) => value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
    const parseNumber = (value) => parseLocalizedStringNumberToNumber(value, 2, userPreferences.decimalSeparator);

    const getCaretPosition = (text, originalLength, caretPosition) => {
        const textLength = text.length;
        const diff = textLength - originalLength;
        return caretPosition + diff;
    };

    const formatValue = (value, eventType) => {
        if (value.indexOf(decimalSeparator) >= 0) {
            const decimalPosition = value.indexOf(decimalSeparator);

            const leftSide = formatNumber(value.substring(0, decimalPosition));
            const rightSide = eventType === 'blur' ? `${formatNumber(value.substring(decimalPosition))}00` : formatNumber(value.substring(decimalPosition));

            const limitedDecimal = rightSide.substring(0, 2);
            return `${leftSide}${decimalSeparator}${limitedDecimal}`;

        } else {
            return value !== '' ? `${formatNumber(value)}${eventType === 'blur' ? `${decimalSeparator}00` : ''}` : '';
        }
    };

    const handleChange = (event, value, triggerOnChange) => {
        if (triggerOnChange && fieldConfiguration.onChange) fieldConfiguration.onChange(value);
    };

    const onInputHandler = (event, triggerOnChange) => {
        const max = fieldConfiguration.optionalConfiguration?.max;
        const eventType = event.type;
        const { value } = event.target;

        if (isEmptyString(value) || isNull(value) || isUndefined(value) || isNaNValue(parseNumber(value.toString()))) {
            setInputContext({
                value: '',
                caretPosition: 0,
            });
            handleChange(event, undefined, triggerOnChange);
            return;
        }
        const functionContextValue = value.toString().replace('.', decimalSeparator);

        const parsedValue = parseNumber(functionContextValue);

        const validatedValue = max ? Math.min(parsedValue, max) : parsedValue;
        const validatedStringValue = validatedValue === parsedValue ? functionContextValue : validatedValue.toString().replace('.', decimalSeparator);

        if (!fieldConfiguration.onChange) return;

        const formattedValue = formatValue(validatedStringValue, eventType);

        const originalLength = value.toString().length;
        const caretPosition = validatedValue === max ? originalLength : event.target.selectionStart || 0;

        setInputContext({
            value: formattedValue,
            caretPosition: getCaretPosition(formattedValue, originalLength, caretPosition),
        });
        handleChange(event, validatedValue, triggerOnChange);
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.setSelectionRange(inputContext.caretPosition, inputContext.caretPosition);
        }
    }, [inputContext.caretPosition]);

    useEffect(() => {
        const parsedValue = fieldConfiguration.value
            ? parseNumber(fieldConfiguration.value.toString())
            : fieldConfiguration.value;
        const parsedInputValue = parseNumber(inputContext.value);
        if (parsedValue !== parsedInputValue) onInputHandler({ target: { value: fieldConfiguration.value }, type: 'blur' }, false);
    }, [fieldConfiguration.value]);
    return (
        <InputGroup>
            {inputLeftElement}
            <Input
                name={fieldConfiguration.name}
                placeholder={fieldConfiguration.placeholderText}
                type="text"
                inputMode="numeric"
                id={fieldConfiguration.id}
                value={inputContext.value}
                ref={inputRef}
                onChange={(event) => onInputHandler(event, true)}
                onBlur={(event) => onInputHandler(event, false)}
                onFocus={fieldConfiguration.onFocus}
                onKeyDown={fieldConfiguration.onKeyDown}
                isDisabled={fieldConfiguration?.optionalConfiguration?.isDisabled}
                aria-valuemin={fieldConfiguration.optionalConfiguration?.min}
                aria-valuemax={fieldConfiguration.optionalConfiguration?.max}
                data-test-id={fieldConfiguration.dataTestId}
                fontSize={'lg'}
            />
            {inputRightElement}

        </InputGroup>
    );
}
export default StandardNumberInput;
