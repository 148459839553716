import { createAction } from '@reduxjs/toolkit';
import { ExternalLocation } from 'models/app/navigation';

import {
    SET_NEW_CONSENTS_MODE,
    CLEAR_NEW_CONSENTS_MODE,
    SetNewConsentModePayload,
    CHANGE_CONSENT_STATUS,
    CHANGE_CONSENT_STATUS_FAILURE,
    CHANGE_CONSENT_STATUS_SUCCESS,
    ChangeConsentStatusPayload,
    NEW_CONSENT_HAS_EXPIRED,
    NEW_CONSENT_NOT_FOUND,
    UNAUTHORISED_DECLINE_CONSENT_REQUEST, UnauthorisedDeclineConsentRequestPayload,
} from './actions.types';


export const setNewConsentMode = createAction(SET_NEW_CONSENTS_MODE,
    (payload: SetNewConsentModePayload) => ({ payload }));

export const clearNewConsentMode = createAction(CLEAR_NEW_CONSENTS_MODE);
export const newConsentHasExpired = createAction(NEW_CONSENT_HAS_EXPIRED);
export const newConsentNotFound = createAction(NEW_CONSENT_NOT_FOUND);

export const unauthorisedDeclineConsentRequest = createAction(UNAUTHORISED_DECLINE_CONSENT_REQUEST,
    (payload: UnauthorisedDeclineConsentRequestPayload) => ({ payload }));


export const changeConsentStatus = createAction(CHANGE_CONSENT_STATUS,
    (payload: ChangeConsentStatusPayload) => ({ payload }));

export const changeConsentStatusSuccess = createAction(CHANGE_CONSENT_STATUS_SUCCESS,
    (payload: ExternalLocation) => ({ payload }));


export const changeConsentStatusFailure = createAction(CHANGE_CONSENT_STATUS_FAILURE);
