import { Action, Location } from 'history';
import { PermissionName } from 'models/domain/permissions';

export type LocationPathname = string;
export type ExternalLocation = string;
export const globalLocation = 'GLOBAL';

export enum RedirectionToDefaultPathTypes {
    ROUTER_REDIRECTION_TO_DEFAULT_PATH = 'ROUTER_REDIRECTION_TO_DEFAULT_PATH',
    STANDARD_TABS_ACTIVE_MAIN_TAB_REPLACEMENT = 'STANDARD_TABS_ACTIVE_MAIN_TAB_REPLACEMENT',
    STANDARD_TABS_ACTIVE_SUB_TAB_REPLACEMENT = 'STANDARD_TABS_ACTIVE_SUB_TAB_REPLACEMENT',
}

export enum NavigationDirectionTypes {
    BACKWARD = 'BACKWARD',
    FORWARD = 'FORWARD',
    SELF = 'SELF',
}


export enum LocationChangeActionScopeTypes {
    TAB_CHANGE = 'TAB_CHANGE',
    PAGE_CHANGE = 'PAGE_CHANGE',
}

export enum AnimationType {
    slideLeft = 'slideLeft',
    slideRight = 'slideRight',
}


export interface RouteConfiguration {
    path: string
    // element: React.ReactElement
    element: any
    hasPermission?: PermissionName
    hasPermissions?: PermissionName[]
    hasOneOfPermissions?: PermissionName[]
    isIndex?: boolean
    childrenRoutes?: any[]
}

export interface LocationPathnameOnly {
    locationPathname: LocationPathname
}

export interface LocationChangeRequest {
    locationPathname: LocationPathname
    meta?: {
        replace?: boolean,
        state?: any
    }
}


export interface EnhancedLocation extends Omit<Location, 'state'> {
    hash: string
    key: string
    search: string
    pathname: string
    action: Action
    direction: NavigationDirectionTypes
    state?: any

}

export default {
    AnimationType,
    RedirectionToDefaultPathTypes,
    NavigationDirectionTypes,
};
