import React from 'react';
import {
    FormControl, FormErrorMessage, FormHelperText, InputGroup, ScaleFade, Skeleton, SkeletonText,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

import { LanguageDirection } from 'models/app/i18n';
import { useLanguageDirection } from 'hook/useLanguageDirection';

import { getPropObj } from 'utils/object-tools';
import { FormFieldWrapperProps } from './FormField.types';
import {
    renderFormInputType,
    renderFormLabel,
    renderInputLeftElement,
    renderInputRightElement,
} from './FormFieldWrapper.helpers';


function FormFieldWrapper({
    fieldConfiguration,
    control,
    errors,
}: FormFieldWrapperProps): React.ReactElement {
    const languageDirection = useLanguageDirection();
    const reverseIcons = languageDirection === LanguageDirection.rtl;


    if (fieldConfiguration?.name) {
        const error = getPropObj(errors, fieldConfiguration.name);

        return (
            <Controller
                name={fieldConfiguration.name}
                control={control}
                render={({ field, fieldState, formState }) => (
                    <FormControl
                        paddingBottom={'20px'}
                        isInvalid={!!error}
                        isRequired={!!fieldConfiguration?.optionalConfiguration?.isRequired}
                        className={fieldConfiguration.optionalConfiguration?.isHoneypot ? 'hpf' : ''}
                    >
                        <>
                            {renderFormLabel(fieldConfiguration)}

                            <InputGroup isolation="unset">
                                {renderInputLeftElement({ fieldConfiguration, reverseIcons })}

                                {renderFormInputType({
                                    field,
                                    fieldState,
                                    formState,
                                    fieldConfiguration,
                                    error,
                                })}

                                {renderInputRightElement({ fieldConfiguration, reverseIcons })}

                            </InputGroup>

                            <div style={{ position: 'relative' }}>
                                {!error ? (
                                    <FormHelperText
                                        fontSize="md"
                                    >
                                        {`${fieldConfiguration?.hintText || ''}`}
                                    </FormHelperText>
                                ) : (
                                    <ScaleFade initialScale={0.9} in={!!error?.message}>
                                        <FormErrorMessage
                                            fontSize="md"
                                            data-test-id={`${fieldConfiguration.dataTestId}-error-message`}
                                        >
                                            {`${error?.message || ''}`}
                                        </FormErrorMessage>
                                    </ScaleFade>
                                )}
                            </div>
                        </>

                    </FormControl>
                )}
            />
        );
    } else {
        return (
            <div>
                <SkeletonText height="25px" width="100%" />
                <Skeleton height="50px" width="100%" />
            </div>
        );
    }
}


export default FormFieldWrapper;

