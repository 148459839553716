// eslint-disable-next-line no-restricted-imports
import {
    isArray, isDate, isNumber, isNaNValue, isUndefined, isNull, isEmptyString, isObject,
} from 'is-what'; // legit use
// eslint-disable-next-line no-restricted-imports
export { flattenObject, flattenArray, flatten } from 'flatten-anything'; // legit use
// eslint-disable-next-line no-restricted-imports
export { nestifyObject } from 'nestify-anything'; // legit use

// eslint-disable-next-line no-restricted-imports
export { default as isDeepEqual } from 'fast-deep-equal'; // legit use


export function getObjectKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
}

export {
    isArray, isDate, isNumber, isNaNValue, isUndefined, isNull, isEmptyString, isObject,
};

export function getPropObj(object, keys) {
    if (!keys || !object) return undefined;
    const keysArray = keys.split('.');
    return keysArray.reduce((picked, key) => {
        if (picked && picked.hasOwnProperty(key)) {
            return picked[key];
        }
        return undefined;
    }, object);
}
